import React from "react"
import {Col, Row} from "reactstrap"

//Import Images

const FooterLink = () => {
  return (
    <React.Fragment>
      <Row>
        <Col lg="6">
          <p className="mb-2">
            {new Date().getFullYear()} © MIDENATT INVESTMENTS LIMITED
          </p>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default FooterLink
