import React from "react"
import {Container, Row, Col} from "reactstrap"
import {Link} from "react-router-dom"

//Import Components
import FooterLink from "./footer-link"

const Features = () => {
    const footerLinks = [
        {
            title: "Company",
            links: [
                {title: "About Us", link: "#about"},
                {title: "Services", link: "#services"},
                {title: "Management", link: "#management"},
                {title: "Associates", link: "#associates"},
            ],
        },
        {
            title: "",
            links: [

            ],
        },
        {
            title: "",
            links: [

            ],
        },
    ]

    return (
        <React.Fragment>
            <footer className="landing-footer">
                <Container>
                    <Row>
                        {footerLinks.map((footerLink, key) => (
                            <Col lg="3" sm="6" key={key}>
                                <div className="mb-4 mb-lg-0">
                                    <h5 className="mb-3 footer-list-title">{footerLink.title}</h5>
                                    <ul className="list-unstyled footer-list-menu">
                                        {footerLink.links.map((Flink, key) => (
                                            <li key={key}>
                                                <Link to={Flink.link}>{Flink.title}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </Col>
                        ))}

                        <Col lg="3" sm="6">
                            <div className="mb-4 mb-lg-0">
                                <h5 className="mb-3 footer-list-title">Contact Us</h5>
                                <div className="blog-post">
                                    <Link to="#" className="post">
                                        <h5 className="post-title">Midenatt Investments Limited</h5>
                                        <p className="mb-0">
                                            6 th Floor, Corner House, Wing B
                                        </p>
                                        <p className="mb-0">Kimathi Street, next to Hilton Hotel,</p>
                                    </Link>
                                </div>
                                <div className="blog-post mt-3">
                                    <Link to="#" className="post">
                                        <h5 className="post-title">Telephone</h5>
                                        <p className="mb-0">
                                            +254-751 663 955; +254-723-036862;
                                        </p>
                                    </Link>
                                </div>
                                <div className="blog-post mt-3">
                                    <Link to="#" className="post">
                                        <h5 className="post-title">Email</h5>
                                        <p className="mb-0">
                                            info@midenatt.co.ke,
                                        </p>
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <hr className="footer-border my-5"/>

                    <FooterLink/>
                </Container>
            </footer>
        </React.Fragment>
    )
}

export default Features
