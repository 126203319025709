import React, {useState} from "react"
import {Card, CardBody, Col, Container, Row} from "reactstrap"


const AboutUs = () => {

    return (
        <React.Fragment>
            <section className="section pt-6 bg-white" id="about">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="text-center mb-5">
                                <h4>About Us</h4>
                                <div className="small-title">Learn more about Midenatt Investments Limited</div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col lg="7">
                            <div className="text-muted">
                                <p>
                                    Midenatt Investments Limited a well-established Accounting, Consultancy, Real Estate
                                    and Virtual Office Leasing
                                    and Investments Company was incorporated as a Private Limited Company under the
                                    Companies Act (Cap 486)
                                    of the Laws of Kenya on April 27 th 2012.
                                </p>
                                <p className="mb-4">
                                    Midenatt Investments Limited is also licensed by the County Council of Nairobi to
                                    provide specialized Book
                                    Keeping, Accounting, Taxation and Consultancy services.
                                </p>

                                <p className="mb-4">
                                    The Directors of Midenatt Investments Limited own 100% Shareholding in Belfast
                                    Management Limited. Belfast Insurance Agency Limited and 50% Shareholding in Bellamy
                                    Services Limited operating and managing the Real Estate and Office Leasing
                                    Businesses.
                                </p>


                            </div>
                        </Col>

                        <Col lg="5" className="ms-auto">
                            <div className="mt-4 mt-lg-0">
                                <Row>
                                    <Col sm="12">
                                        <Card className="border">
                                            <CardBody>
                                                <h5>Our Core Values</h5>
                                                <p className="text-muted mb-0">
                                                    As a company we are guided by
                                                </p>
                                                <div className={"mt-3"}>
                                                    {[
                                                        'Ethics and Professionalism',
                                                        'Honesty and Integrity',
                                                        'Accountability & Transparency',
                                                        'Creativity and Innovation',
                                                        'Social Responsibility'
                                                    ].map((i) => <p className="text-muted">
                                                        <i className="fa fa-bullseye font-size-16 align-middle text-primary me-2"/>
                                                        {i}
                                                    </p>)}
                                                </div>

                                                <p className="text-muted mb-0">
                                                    We will at all times observe the highest principles of ethical
                                                    behavior, respect for society, the rule of the law and
                                                    the environment.</p>
                                            </CardBody>
                                        </Card>

                                        <Card className="border">
                                            <CardBody>
                                                <h5>Our Motto</h5>
                                                <p className="text-muted mb-0">
                                                    Growing together in partnership
                                                </p>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    <hr className="mt-5 mb-0"/>

                    {/*<Row>*/}
                    {/*    <Col lg="12">*/}
                    {/*        <Col lg="12">*/}
                    {/*            <div className="hori-timeline">*/}
                    {/*                <div*/}
                    {/*                    className="owl-carousel owl-theme  navs-carousel events"*/}
                    {/*                    id="timeline-carousel"*/}
                    {/*                >*/}
                    {/*                    {step1 ? (*/}
                    {/*                        <>*/}
                    {/*                            <Row>*/}
                    {/*                                <Col md={3}>*/}
                    {/*                                    <div className="item">*/}
                    {/*                                        <div className="client-images">*/}
                    {/*                                            <img*/}
                    {/*                                                src={client1}*/}
                    {/*                                                alt="client-img"*/}
                    {/*                                                className="mx-auto img-fluid d-block"*/}
                    {/*                                            />*/}
                    {/*                                        </div>*/}
                    {/*                                    </div>*/}
                    {/*                                </Col>*/}
                    {/*                                <Col md={3}>*/}
                    {/*                                    <div className="item">*/}
                    {/*                                        <div className="client-images">*/}
                    {/*                                            <img*/}
                    {/*                                                src={client2}*/}
                    {/*                                                alt="client-img"*/}
                    {/*                                                className="mx-auto img-fluid d-block"*/}
                    {/*                                            />*/}
                    {/*                                        </div>*/}
                    {/*                                    </div>*/}
                    {/*                                </Col>*/}
                    {/*                                <Col md={3}>*/}
                    {/*                                    <div className="item">*/}
                    {/*                                        <div className="client-images">*/}
                    {/*                                            <img*/}
                    {/*                                                src={client3}*/}
                    {/*                                                alt="client-img"*/}
                    {/*                                                className="mx-auto img-fluid d-block"*/}
                    {/*                                            />*/}
                    {/*                                        </div>*/}
                    {/*                                    </div>*/}
                    {/*                                </Col>*/}
                    {/*                                <Col md={3}>*/}
                    {/*                                    <div className="item">*/}
                    {/*                                        <div className="client-images">*/}
                    {/*                                            <img*/}
                    {/*                                                src={client4}*/}
                    {/*                                                alt="client-img"*/}
                    {/*                                                className="mx-auto img-fluid d-block"*/}
                    {/*                                            />*/}
                    {/*                                        </div>*/}
                    {/*                                    </div>*/}
                    {/*                                </Col>*/}
                    {/*                            </Row>*/}
                    {/*                        </>*/}
                    {/*                    ) : null}*/}

                    {/*                    {step2 ? (*/}
                    {/*                        <>*/}
                    {/*                            <Row>*/}
                    {/*                                <Col md={3}>*/}
                    {/*                                    <div className="item">*/}
                    {/*                                        <div className="client-images">*/}
                    {/*                                            <img*/}
                    {/*                                                src={client4}*/}
                    {/*                                                alt="client-img"*/}
                    {/*                                                className="mx-auto img-fluid d-block"*/}
                    {/*                                            />*/}
                    {/*                                        </div>*/}
                    {/*                                    </div>*/}
                    {/*                                </Col>*/}
                    {/*                                <Col md={3}>*/}
                    {/*                                    <div className="item">*/}
                    {/*                                        <div className="client-images">*/}
                    {/*                                            <img*/}
                    {/*                                                src={client5}*/}
                    {/*                                                alt="client-img"*/}
                    {/*                                                className="mx-auto img-fluid d-block"*/}
                    {/*                                            />*/}
                    {/*                                        </div>*/}
                    {/*                                    </div>*/}
                    {/*                                </Col>*/}
                    {/*                                <Col md={3}>*/}
                    {/*                                    <div className="item">*/}
                    {/*                                        <div className="client-images">*/}
                    {/*                                            <img*/}
                    {/*                                                src={client6}*/}
                    {/*                                                alt="client-img"*/}
                    {/*                                                className="mx-auto img-fluid d-block"*/}
                    {/*                                            />*/}
                    {/*                                        </div>*/}
                    {/*                                    </div>*/}
                    {/*                                </Col>*/}
                    {/*                            </Row>*/}
                    {/*                        </>*/}
                    {/*                    ) : null}*/}

                    {/*                    <div className="owl-nav" style={{textAlign: "center"}}>*/}
                    {/*                        <button*/}
                    {/*                            type="button"*/}
                    {/*                            onClick={() => {*/}
                    {/*                                setStep1(true)*/}
                    {/*                                setStep2(false)*/}
                    {/*                            }}*/}
                    {/*                            className="border-0"*/}
                    {/*                            disabled={step1}*/}
                    {/*                        >*/}
                    {/*                            <i className="mdi mdi-chevron-left"/>*/}
                    {/*                        </button>*/}
                    {/*                        <button*/}
                    {/*                            type="button"*/}
                    {/*                            onClick={() => {*/}
                    {/*                                setStep1(false)*/}
                    {/*                                setStep2(true)*/}
                    {/*                            }}*/}
                    {/*                            className="border-0"*/}
                    {/*                            disabled={step2}*/}
                    {/*                        >*/}
                    {/*                            <i className="mdi mdi-chevron-right"/>*/}
                    {/*                        </button>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </Col>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                </Container>
            </section>
        </React.Fragment>
    )
}

export default AboutUs
