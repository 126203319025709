import PropTypes from 'prop-types'
import React from "react"

import {BrowserRouter as Router, Switch} from "react-router-dom"
import {connect} from "react-redux"
import {authRoutes} from "./routes/allRoutes"
import Authmiddleware from "./routes/middleware/Authmiddleware"
import NonAuthLayout from "./components/NonAuthLayout"
import "./assets/scss/theme.scss"
import {ToastHolder} from "./util/Notify"
import {isLocalEnvironment} from "./util/Util";

if (!isLocalEnvironment()) console.log = () => {
};


const App = props => {

    return (
        <React.Fragment>
            <Router>
                <Switch>
                    {authRoutes.map((route, idx) => (
                        <Authmiddleware
                            path={route.path}
                            layout={NonAuthLayout}
                            component={route.component}
                            key={idx}
                        />
                    ))}

                </Switch>
            </Router>
            <ToastHolder/>
        </React.Fragment>
    )
}

App.propTypes = {
    layout: PropTypes.any
}

const mapStateToProps = state => {
    return {
        layout: state.Layout,
    }
}

export default connect(mapStateToProps, null)(App)
