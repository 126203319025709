import React from 'react';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ERROR = toast.TYPE.ERROR;
export const SUCCESS = toast.TYPE.SUCCESS;
export const WARNING = toast.TYPE.WARNING;
export const INFO = toast.TYPE.INFO;

export const Notify = (message, type = INFO, playSound = false) => {
    toast(message, {
        type: type,
        position: toast.POSITION.BOTTOM_LEFT
    });
    if (playSound) playNotificationSound()
};

const playNotificationSound = () => {
    window.ion.sound.play("door_bell");
}

export const ToastHolder = props => {
    return <ToastContainer/>
};
