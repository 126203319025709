import React from "react"
import {Col, Container, Row} from "reactstrap"

//Import Components
import FeatureBox from "./feature-box"

//Import images
import feature1 from "../../assets/images/crypto/features-img/img-1.png"
import feature2 from "../../assets/images/crypto/features-img/img-2.png"

const Features = () => {

    return (
        <React.Fragment>
            <section className="section bg-white" id="services">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="text-center mb-5">
                                <h4>Services</h4>
                                <div className="small-title">Learn more about services we offer</div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="align-items-center pt-4">
                        <Col md="6" sm="8">
                            <div>
                                <img
                                    src={feature1}
                                    alt=""
                                    className="img-fluid mx-auto d-block"
                                />
                            </div>
                        </Col>
                        <Col md="5" className="ms-auto">
                            {/* featurebox */}
                            <FeatureBox
                                num="01"
                                title="Accounting &amp; Book-keeping Services"
                                features={[]}
                                desc=""
                            />
                        </Col>
                    </Row>

                    <Row className="align-items-center mt-5 pt-md-5">
                        <Col md="5">
                            {/* featurebox */}
                            <FeatureBox
                                num="02"
                                title="Taxation Services"
                                features={[]}
                                desc=""
                            />
                        </Col>
                        <Col md="6" sm="8" className="ms-md-auto">
                            <div className="mt-4 me-md-0">
                                <img
                                    src={feature2}
                                    alt=""
                                    className="img-fluid mx-auto d-block"
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row className="align-items-center pt-4">
                        <Col md="6" sm="8">
                            <div>
                                <img
                                    src={feature1}
                                    alt=""
                                    className="img-fluid mx-auto d-block"
                                />
                            </div>
                        </Col>
                        <Col md="5" className="ms-auto">
                            {/* featurebox */}
                            <FeatureBox
                                num="03"
                                title="Consultancy & Financial Advisory Services"
                                features={[]}
                                desc=""
                            />
                        </Col>
                    </Row>

                    <Row className="align-items-center mt-5 pt-md-5">
                        <Col md="5">
                            {/* featurebox */}
                            <FeatureBox
                                num="04"
                                title="Real Estates &amp; Property Management Services"
                                features={[]}
                                desc=""
                            />
                        </Col>
                        <Col md="6" sm="8" className="ms-md-auto">
                            <div className="mt-4 me-md-0">
                                <img
                                    src={feature2}
                                    alt=""
                                    className="img-fluid mx-auto d-block"
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row className="align-items-center pt-4">
                        <Col md="6" sm="8">
                            <div>
                                <img
                                    src={feature1}
                                    alt=""
                                    className="img-fluid mx-auto d-block"
                                />
                            </div>
                        </Col>
                        <Col md="5" className="ms-auto">
                            {/* featurebox */}
                            <FeatureBox
                                num="05"
                                title="Office Space Rental & Leasing Services"
                                features={[]}
                                desc=""
                            />
                        </Col>
                    </Row>


                </Container>
            </section>
        </React.Fragment>
    )
}

export default Features
