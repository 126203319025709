import React, {useState} from "react"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Nav,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap"
import classnames from "classnames"

//Import Components
import Accordian from "./accordian"

const FAQs = () => {
    const [activeTab, setactiveTab] = useState("1")

    const associates = [
        {
            name: 'Belfast Insurance Agency Limited',
            des: 'Belfast Insurance Agency Limited is an independent Insurance Agency set up to provide independent Insurance\n' +
                'services and solutions.Based in Kenya, Belfast Insurance Agency specializes in personal and commercial insurance, as well as financial\n' +
                'services.\n' +
                'Since 2018, Belfast Insurance Agency Limited has provided insurance services and solutions to individuals,\n' +
                'professionals, and business owners throughout the country.\n' +
                'At Belfast Insurance Agency Limited, we are all about relationships and putting our customers’ best interests first.\n' +
                'If you are looking for an insurance agency to build a long-term relationship with, to assist in all facets of risk\n' +
                'management.'
        },
        {
            name: 'Belfast Management Limited',
            des: 'Belfast Management Limited is fully owned by Directors of Midenatt Investments Limited. Belfast\n' +
                'Management owns a number of residential Apartments and Properties earning a sizeable amount of\n' +
                'Rental Income.'
        },
        {
            name: 'Bellamy Services Limited',
            des: 'Belfast Management Limited and Bellamy Services Limited have rented up a number of Executive Virtual\n' +
                'Office Spaces within the CBD in Nairobi. Plans are underway to expand to other major Cities in Kenya.The Executive Virtual Office spaces are sub divided and Partitioned into smaller Offices. The Smaller\n' +
                'Offices of Approximately 200Sqft are rented out to individuals at affordable rent of between Sh25, 000\n' +
                'and Sh50, 000 per month.'
        },
    ]

    return (
        <React.Fragment>
            <section className="section" id="associates">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="text-center mb-5">
                                <h4>Our Associates</h4>
                                <div className="small-title">Midenatt Investments Limited owns shares in the following
                                    Companies
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12">
                            <div className="vertical-nav">
                                <Row>
                                    <Col sm="12">
                                        <Card>
                                            <CardBody>
                                                <TabContent activeTab={activeTab}>
                                                    <TabPane tabId="1" id="buy">
                                                        <Accordian
                                                            question1={associates[0].name}
                                                            answer1={associates[0].des}
                                                            question2={associates[1].name}
                                                            answer2={associates[1].des}
                                                            question3={associates[2].name}
                                                            answer3={associates[2].des}
                                                        />
                                                    </TabPane>
                                                </TabContent>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default FAQs
