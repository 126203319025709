import React, {useEffect} from "react"
import {Modal} from "reactstrap";

const TeamDetails = props => {
    const toggle = () => props.setOpen(!props.open)

    const {currentTeam} = props

    useEffect(() => {

    }, [props.open])

    return (
        <Modal
            isOpen={props.open}
            toggle={toggle}
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    {currentTeam.name}
                </h5>
                <button
                    type="button"
                    onClick={toggle}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <h5>{currentTeam.position}</h5>
                <p>
                    {currentTeam.des}
                </p>

            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={toggle}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    Close
                </button>
            </div>
        </Modal>
    )
}


export default TeamDetails
