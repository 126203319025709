import React, {useState} from "react"
import {Card, CardBody, Col, Container, Row} from "reactstrap"

//Images

const MoreInfo = () => {

    return (
        <React.Fragment>
            <section className="section pt-4 bg-white" id="about">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="text-center mb-5">
                                <h4>More Information</h4>
                                <div className="small-title">Learn more about Midenatt Investments Limited</div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col lg="12" className="ms-auto">
                            <div className="mt-4 mt-lg-0">
                                <Row>
                                    <Col sm="12">
                                        <Card className="border">
                                            <CardBody>
                                                <h5>PRESENT &amp; FUTURE INVESTMENT PLANS</h5>
                                                <p className="text-muted mb-0">
                                                    Midenatt Investments Limited has leases and operates a number of
                                                    various Buildings within the CBD, earning a
                                                    sizeable amount of Tenants Rental Income.
                                                    As part of the Long term Investments Plan, Midenatt Investments
                                                    Limited plans to expand its Tenants Base by
                                                    increasing the Virtual Office Business Suites for Short term hire,
                                                    Board Room and Meetings hire of space.
                                                    The Office spaces are usually partitioned into smaller affordable
                                                    offices for rent to SME’S
                                                </p>
                                            </CardBody>
                                        </Card>

                                        <Card className="border">
                                            <CardBody>
                                                <h5>REGULATIONS</h5>
                                                <p className="text-muted mb-0">
                                                    All organizations operate in an environment, largely governed by
                                                    laws, rules and regulations. Midenatt
                                                    Investments Limited is no exception. Midenatt Investments Limited
                                                    maintains a strict policy on the adherence of
                                                    the law, rules and regulations and internal policies. Specifically,
                                                    Midenatt Ltd puts emphasis in the following.
                                                </p>
                                            </CardBody>
                                        </Card>

                                        <Card className="border">
                                            <CardBody>
                                                <h5>INSTITUTE OF CERTIFIED PUBLIC ACCOUNTANTS OF KENYA - ICPAK</h5>
                                                <p className="text-muted mb-0">
                                                    ICPAK is an institution mandated to protect and uphold Public
                                                    Interest as well as develop and regulate the
                                                    Accountancy Profession in Kenya. The Managing Director is registered
                                                    as a member of the Institute of Certified
                                                    Public Accountants (ICPAK). Midenatt Investments Limited operates
                                                    within the stipulations of this Institution.
                                                </p>
                                            </CardBody>
                                        </Card>

                                        <Card className="border">
                                            <CardBody>
                                                <h5>GENERAL LAWS &amp; REGULATIONS</h5>
                                                <p className="text-muted mb-0">
                                                    Midenatt Investments Limited is registered as a Limited Liability
                                                    Company under the Companies Act, Cap 486,
                                                    Laws of Kenya and operates within the stipulations of this law.
                                                </p>
                                            </CardBody>
                                        </Card>

                                        <Card className="border">
                                            <CardBody>
                                                <h5>CORPORATE SOCIAL RESPONSIBILITY (CSR)</h5>
                                                <p className="text-muted mb-0">
                                                    CSR remains an integral part in the policies and operations of
                                                    Midenatt Credit Limited. We believe that CSR is an
                                                    important activity to all businesses in the Economy. We also believe
                                                    in giving back part of our fortunes to the
                                                    society. Based on our values and relationship with our stakeholders
                                                    we have developed a systematic approach to
                                                    Corporate Social Responsibility (CSR).
                                                    A major part of our company’s CSR activities are linked to Company’s
                                                    relationship with our Business partners and communities in general. We focus primarily on projects pertaining to
                                                    Community empowerment and development,
                                                    Food and Shelter, Education for the less fortunate, Health care and
                                                    Environmental awareness.
                                                    Midenatt Investments Limited has lined up and budgeted for a variety
                                                    of CSR social activities linked to youths,
                                                    education for the needy &amp; less fortunate, community and
                                                    Children’s’ home support during the 2021/2 Financial
                                                    Year.
                                                </p>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    <hr className="mt-5 mb-0"/>

                    {/*<Row>*/}
                    {/*    <Col lg="12">*/}
                    {/*        <Col lg="12">*/}
                    {/*            <div className="hori-timeline">*/}
                    {/*                <div*/}
                    {/*                    className="owl-carousel owl-theme  navs-carousel events"*/}
                    {/*                    id="timeline-carousel"*/}
                    {/*                >*/}
                    {/*                    {step1 ? (*/}
                    {/*                        <>*/}
                    {/*                            <Row>*/}
                    {/*                                <Col md={3}>*/}
                    {/*                                    <div className="item">*/}
                    {/*                                        <div className="client-images">*/}
                    {/*                                            <img*/}
                    {/*                                                src={client1}*/}
                    {/*                                                alt="client-img"*/}
                    {/*                                                className="mx-auto img-fluid d-block"*/}
                    {/*                                            />*/}
                    {/*                                        </div>*/}
                    {/*                                    </div>*/}
                    {/*                                </Col>*/}
                    {/*                                <Col md={3}>*/}
                    {/*                                    <div className="item">*/}
                    {/*                                        <div className="client-images">*/}
                    {/*                                            <img*/}
                    {/*                                                src={client2}*/}
                    {/*                                                alt="client-img"*/}
                    {/*                                                className="mx-auto img-fluid d-block"*/}
                    {/*                                            />*/}
                    {/*                                        </div>*/}
                    {/*                                    </div>*/}
                    {/*                                </Col>*/}
                    {/*                                <Col md={3}>*/}
                    {/*                                    <div className="item">*/}
                    {/*                                        <div className="client-images">*/}
                    {/*                                            <img*/}
                    {/*                                                src={client3}*/}
                    {/*                                                alt="client-img"*/}
                    {/*                                                className="mx-auto img-fluid d-block"*/}
                    {/*                                            />*/}
                    {/*                                        </div>*/}
                    {/*                                    </div>*/}
                    {/*                                </Col>*/}
                    {/*                                <Col md={3}>*/}
                    {/*                                    <div className="item">*/}
                    {/*                                        <div className="client-images">*/}
                    {/*                                            <img*/}
                    {/*                                                src={client4}*/}
                    {/*                                                alt="client-img"*/}
                    {/*                                                className="mx-auto img-fluid d-block"*/}
                    {/*                                            />*/}
                    {/*                                        </div>*/}
                    {/*                                    </div>*/}
                    {/*                                </Col>*/}
                    {/*                            </Row>*/}
                    {/*                        </>*/}
                    {/*                    ) : null}*/}

                    {/*                    {step2 ? (*/}
                    {/*                        <>*/}
                    {/*                            <Row>*/}
                    {/*                                <Col md={3}>*/}
                    {/*                                    <div className="item">*/}
                    {/*                                        <div className="client-images">*/}
                    {/*                                            <img*/}
                    {/*                                                src={client4}*/}
                    {/*                                                alt="client-img"*/}
                    {/*                                                className="mx-auto img-fluid d-block"*/}
                    {/*                                            />*/}
                    {/*                                        </div>*/}
                    {/*                                    </div>*/}
                    {/*                                </Col>*/}
                    {/*                                <Col md={3}>*/}
                    {/*                                    <div className="item">*/}
                    {/*                                        <div className="client-images">*/}
                    {/*                                            <img*/}
                    {/*                                                src={client5}*/}
                    {/*                                                alt="client-img"*/}
                    {/*                                                className="mx-auto img-fluid d-block"*/}
                    {/*                                            />*/}
                    {/*                                        </div>*/}
                    {/*                                    </div>*/}
                    {/*                                </Col>*/}
                    {/*                                <Col md={3}>*/}
                    {/*                                    <div className="item">*/}
                    {/*                                        <div className="client-images">*/}
                    {/*                                            <img*/}
                    {/*                                                src={client6}*/}
                    {/*                                                alt="client-img"*/}
                    {/*                                                className="mx-auto img-fluid d-block"*/}
                    {/*                                            />*/}
                    {/*                                        </div>*/}
                    {/*                                    </div>*/}
                    {/*                                </Col>*/}
                    {/*                            </Row>*/}
                    {/*                        </>*/}
                    {/*                    ) : null}*/}

                    {/*                    <div className="owl-nav" style={{textAlign: "center"}}>*/}
                    {/*                        <button*/}
                    {/*                            type="button"*/}
                    {/*                            onClick={() => {*/}
                    {/*                                setStep1(true)*/}
                    {/*                                setStep2(false)*/}
                    {/*                            }}*/}
                    {/*                            className="border-0"*/}
                    {/*                            disabled={step1}*/}
                    {/*                        >*/}
                    {/*                            <i className="mdi mdi-chevron-left"/>*/}
                    {/*                        </button>*/}
                    {/*                        <button*/}
                    {/*                            type="button"*/}
                    {/*                            onClick={() => {*/}
                    {/*                                setStep1(false)*/}
                    {/*                                setStep2(true)*/}
                    {/*                            }}*/}
                    {/*                            className="border-0"*/}
                    {/*                            disabled={step2}*/}
                    {/*                        >*/}
                    {/*                            <i className="mdi mdi-chevron-right"/>*/}
                    {/*                        </button>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </Col>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                </Container>
            </section>
        </React.Fragment>
    )
}

export default MoreInfo
