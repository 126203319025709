import React, {useState} from "react"
import {Col, Container, Modal, Row} from "reactstrap"

//Import Images
import avatar2 from "../../assets/images/users/avatar-2.png"
import {Link} from "react-router-dom";
import TeamDetails from "./team-details";

const OurTeam = () => {
    const [step1, setStep1] = useState(true)
    const [step2, setStep2] = useState(false)

    const [currentTeam, setCurrentTeam] = useState({})

    const [openModal, setOpenModal] = useState(false)

    const teams = [
        {
            name: "Francis Wanyoike",
            position: "Managing Director",
            des: "Mr. Wanyoike is a founder member and the Chief Executive Officer at Midenatt Investments Limited. He holds a\n" +
                "Bachelor of Commerce Degree (B-Comm) and Masters in Business Administration (MBA-Finance) from United\n" +
                "States International University (USIU).\n" +
                "He is a fully qualified Accountant CPA-(K) and member of the Institute of Certified Public Accountants of Kenya\n" +
                "(ICPAK). He has proven experience in Financial Management, Auditing and Accounting having worked in the\n" +
                "Manufacturing, Insurance, Real Estate and Auditing among other sectors in Senior Management positions.\n" +
                "He has vast interests and successful ventures in the Real Estate and Financial Management Businesses. The\n" +
                "Managing Director is responsible for the daily business of the company’s planning and strategic functions."
        },
        {
            name: "Jane Nganga",
            position: "Director",
            des: "Jane Nganga holds an Executive MBA from Kenyatta University and a Bachelor Degree in Business\n" +
                "Administration from Newport University. She is a holder of Higher Diploma in Sales and Marketing and a member\n" +
                "of ABMA (Associate of Business Managers and Accountants).\n" +
                "Jane holds a Diploma in Banking. She has over 25 years’ experience in in the Banking Industry both local and International. She is currently a\n" +
                "Deputy Director in Retail Banking at NCBA Bank where she provides leadership to people in Branch Business.\n" +
                "Prior to this Jane held the position of Regional Branch Manager at National Bank of Kenya. She previously\n" +
                "worked at (ABSA Bank Kenya, formerly Barclays Bank) for 19 years where she grew up the ladder from entry in\n" +
                "various departments to middle and senior level Manager. She has a wealth of Experience in Operations, Business\n" +
                "and Consumer Banking.\n" +
                "Jane is a Dormant Director of Midenatt Investment Limited."
        },
        {
            name: "Francis Maimba",
            position: "Operations & Real Estate Manager",
            des: "Engineer Maimba is the Operations Manager, and the officer in-charge of the company’s Real Estate Operations\n" +
                "and Management. He is an Engineer by Profession with a wide range of experience and exposure in Business\n" +
                "administration, Real Estate, Constructions and Business Operations.\n" +
                "He has wide experience having worked in various Private and Public Institutions and having undertaken major\n" +
                "works and contracts in the Real Estate, Building and Construction industry.\n" +
                "Mr. Maimba has vast interest and a major player in Real Estate Industry."
        },
        {
            name: "Rachel Ngigi",
            position: "Finance & Administration Manager",
            des: "Rachel Ngigi is the Finance & Administrative Manager of Midenatt Investments Limited. She is a fully Qualified\n" +
                "Accountant –CPA-K, with professional knowledge, coupled with hands on experience in Administration and\n" +
                "Finance, Sales and Marketing, Financial Accounting , Customer care and ICT.\n" +
                "Rachel has vast previous experience in the Real Estate and Construction Industries gained over a period of over\n" +
                "15 years. Reporting to the Managing Director, Rachel is in-charge of Staff & Office Administration, Financial\n" +
                "Management and Customer Services Departments overseeing the daily Finance and Administrative functions.\n" +
                "Rachel is also in charge of Credit Control and Management."
        },
        {
            name: "Antony Kinyanjui",
            position: "Marketing Manager",
            des: "Antony Kinyanjui possesses a Bachelors of Commerce Degree from Jomo Kenyatta University of Agriculture and\n" +
                "Technology.\n" +
                "Before joining Midenatt Investments Limited, Antony had worked in one of the leading Commercial Banks in the\n" +
                "field of Sales and Marketing. He has vast experience in marketing of Bank and Insurance products gained over a\n" +
                "period of 12 years.\n" +
                "Antony is in charge of Sales and Marketing Department with the responsibility of outsourcing for new customers,\n" +
                "customer retention and ensuring sustenance of company growth and development. He currently handles a\n" +
                "portfolio of over 180 clients within his docket"
        },
        {
            name: "Steven Masika Kubwa",
            position: "Team Leader, Sales & Marketing",
            des: "Steven is a Bachelors of Science graduate from Masinde Muliro University of Science and Technology.\n" +
                "Before joining Midenatt Investments Limited, Steven had worked in a number of Commercial Banks as a Direct\n" +
                "Sales and Marketing Representative and Customer Advisor.\n" +
                "Steven is in charge of business growth, development and Portfolio Management. He also handles Consultancy\n" +
                "Services, Book keeping and Taxation and Office Rentals. His main priority is to identify, pursue Sales\n" +
                "opportunities and development of a strong customer base. He is currently handling a portfolio of over 140 clients\n" +
                "within his docket"
        },

        {
            name: "Jane Lusweti",
            position: "Team Leader, Sales & Marketing",
            des: "Jane Lusweti is currently pursuing her Bachelors of Commerce Degree in Marketing. She possesses a Diploma in\n" +
                "Business Administration.\n" +
                "She leads a big team is in Sales and Marketing Department and also in charge of a large portfolio of customers\n" +
                "who have purchased our Real Estate products and our Virtual Office Suites customers."
        },

        {
            name: "Michelle Muthoni",
            position: "Information Technology Assistant",
            des: "Michelle is currently pursuing her Bachelors Degree in Information Technology at JKUAT. Information Technology\n" +
                "(IT) Department oversees the installation and maintenance of Computer network systems within the Company.\n" +
                "She is charged with the responsibility of records keeping and Debtors Balances and Statements for all\n" +
                "Customers, Tenants and Creditors."
        },
        {
            name: "Frashiah Nganga",
            position: "Marketing Executive",
            des: "Frashiah possesses a Diploma in Marketing from Zetech College currently pursuing a Degree in Marketing and\n" +
                "Management. Frashiah has worked with various Insurance Companies in the field of Marketing.\n" +
                "She is in Sales and Marketing Department and also in charge of Real Estate and Property Management."
        },
    ]


    return (
        <React.Fragment>
            <section className="section" id="team">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="text-center mb-5">
                                <h4>Management</h4>
                                <div className="small-title">The Key and Management staff are highlighted below</div>
                            </div>
                        </Col>
                    </Row>

                    <Col lg="12">
                        <div className="hori-timeline">
                            <div
                                className="owl-carousel owl-theme  navs-carousel events"
                                id="timeline-carousel"
                            >
                                {step1 ? (
                                    <>
                                        <Row>
                                            {teams.map((a) => <Col md={4}>
                                                <div className="item">
                                                    <div className="card text-center team-box">
                                                        <div className="card-body">
                                                            <div>
                                                                <img src={avatar2} alt="" className="rounded"
                                                                     style={{width: 100}}/>
                                                            </div>

                                                            <div className="mt-3">
                                                                <h5>{a.name}</h5>
                                                                <p className="text-muted mb-0">{a.position}</p>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="card-footer bg-transparent border-top">
                                                            <div className="d-flex mb-0 team-social-links">
                                                                <div className="flex-fill">
                                                                    <button className="btn btn-outline-dark"
                                                                            onClick={e => {
                                                                                setCurrentTeam(a)
                                                                                setOpenModal(true)
                                                                            }}>
                                                                        Details
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>)}

                                        </Row>
                                    </>
                                ) : null}

                                <TeamDetails currentTeam={currentTeam} open={openModal} setOpen={setOpenModal}/>


                            </div>
                        </div>
                    </Col>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default OurTeam
