import React from "react"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Button, Media,
} from "reactstrap"
import {Link} from "react-router-dom"

//Import Countdown
import Countdown from "react-countdown"

const renderer = ({days, hours, minutes, seconds, completed}) => {
    if (completed) {
        // Render a completed state
        return <span>You are good to go!</span>
    } else {
        // Render a countdown
        return (
            <>
                <div className="coming-box">
                    {days}
                    <span>Days</span>
                </div>
                <div className="coming-box">
                    {hours}
                    <span>Hours</span>
                </div>
                <div className="coming-box">
                    {minutes}
                    <span>Minutes</span>
                </div>
                <div className="coming-box">
                    {seconds}
                    <span>Seconds</span>
                </div>
            </>
        )
    }
}

const Section = () => {
    return (
        <React.Fragment>
            <section className="section hero-section bg-ico-hero" id="home">
                <div className="bg-overlay bg-primary"/>
                <Container>
                    <Row className="align-items-center">
                        <Col lg="5">
                            <div className="text-white-50">
                                <h1 className="text-white font-weight-semibold mb-3 hero-title">
                                    MIDENATT INVESTMENTS LIMITED
                                </h1>
                                <p className="font-size-14 text-white">
                                    We believe that we should deliver high quality Services as our main responsibility
                                    to our stakeholders. We
                                    believe that by achieving and maintaining financial sustainability to our clients,
                                    we could continue to pursue our
                                    vision and mission.
                                </p>

                                <div className="button-items mt-4">
                                    <Link to="#about" className="btn btn-success me-1">
                                        Learn more
                                    </Link>
                                </div>
                            </div>
                        </Col>
                        <Col lg="5" md="8" sm="10" className="ms-lg-auto">
                            <Card className="overflow-hidden mb-0 mt-5 mt-lg-0">

                                <CardBody>

                                    <Media className="faq-box mb-4">
                                        <div className="faq-icon me-3">
                                            <i className="fa fa-eye font-size-20 text-success"/>
                                        </div>
                                        <Media body>
                                            <h5 className="font-size-15">
                                                Our Vision
                                            </h5>
                                            <p className="text-muted">
                                                To be part of a Global network in Accountancy, Real Estate, Property
                                                Management &amp; Consultancy Services and
                                                ultimately to become one of the fastest growing companies within the
                                                Region.
                                            </p>
                                        </Media>
                                    </Media>

                                    <Media className="faq-box mb-4">
                                        <div className="faq-icon me-3">
                                            <i className="fa fa-edit font-size-20 text-success"/>
                                        </div>
                                        <Media body>
                                            <h5 className="font-size-15">
                                                Our Mission
                                            </h5>
                                            <p className="text-muted">
                                                To provide Real Estate &amp; Property Management Solutions, Financial
                                                Accounting and Consultancy Services to
                                                Kenyans to help them realize their goals and dreams by offering a wide
                                                portfolio of tailor-made Real Estate
                                                Investments, Virtual Office Suites and Consultancy solutions with
                                                excellent customer service.
                                            </p>
                                        </Media>
                                    </Media>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default Section
